.modalContent {
  display: flex;
}

.modalContentImg {
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContent {
  height: 600px;
  display: flex;
  align-items: center;
}

.iconContentBack {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContent :hover {
  color: white;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.bottomNum{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
