.backBlockMain {
  height: calc(100% - 20px);
  overflow: auto;
  /* width: 24%; */
  width: 400px;
  background-color: #f6f7f7;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  border-radius: 10px;
  margin-left: 4px;
}

.contents :global(.ant-tabs-content-holder) {
  overflow-y: auto !important;
}

.backBlock {
  padding: 15px;
  background-color: #fff;
  margin-bottom: 15px;
}

.icons {
  color: red;
  margin-right: 10px;
}

.placeholderMains {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.placeholderText {
  margin-left: 16px;
  color: #cccccc;
}

.backBlockPicTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lineBlock {
  margin-left: 4px;
  position: relative;
}

.lineBlockTitle {
  width: 350px;
  background-color: #426d9d;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  color: #fff;
}

.lineBlockMain {
  width: 350px;
  display: flex;
  border-radius: 0px 0px 5px 5px;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  color: #333;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.lineIcons {
  display: block;
  transform: rotate(90deg);
  position: relative;
  left: 8%;
}

.changePeopleMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.changePeopleMainLeft {
  width: 70%;
}

.changePeopleMainRight {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.changePeopleMainText {
  font-size: 14px;
  color: #cccccc;
}

.openQueryTitleMain {
  display: flex;
  justify-content: space-between;
}

.openQueryTitleText {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chooseApproverModal :global(.ant-radio-group) {
  margin: 15px 0 10px 15px !important;
  display: flex !important;
  flex-direction: column !important;
  line-height: 24px !important;
}

.placeholderTexts {
  margin-left: 10px;
  color: #101010;
}
