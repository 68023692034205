.icon {
    width: 50px;
    height: 50px;
    background-color:#2D7BFF;
    border-radius: 50%;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgIcon {
    width: 24px;
    height: 24px;
    background: url('./assets//iconPark-android.svg') no-repeat;
}

.box {
    display: flex;
    align-items: center;
    border: 1px solid rgba(176, 205, 255, 1);
    width: 20%;
    height: 90px;
    margin: 0 15px;
    color: #101010;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 15px;
}

.title {
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    padding: 20px 0 15px;
}

.chartTitle {
    color: rgba(16, 16, 16, 1);
    font-size: 17px;
    margin-top: 17px;
    margin-left: 20px;
}