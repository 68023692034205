@value colors: "../colors.css";
@value primary-color, tertiary-color, text-color-2, button-border-color from colors;

.name {
  margin: 0 21px;
  height: 3.75rem;
  line-height: 3.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sider :global(.ant-layout-sider-children) {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.625rem 1.2rem 2.5rem 1.2rem;
  text-align: center;
  fill: primary-color;
  border-radius: 999px;
  background-color: #ffffff;

}

:global(.ant-menu-inline) {
  border-right: none !important;
}

:global(.ant-menu-inline-collapsed-tooltip) {
  display: none !important;
}

:global(.ant-menu-vertical) {
  border-right: none !important;
}

.menu,
.menuCollapsed {
  display: flex !important;
  align-items: center;
  width: 100% !important;
}

.menu {
  padding-left: 20px !important;
}

.menuCollapsed {
  padding: 0px;
}

.menu > :global(.ant-menu-submenu-title) {
  display: flex !important;
  align-items: center;
  width: 100% !important;
  padding: 0 !important;
}

.menu > span,
.menu > :global(.ant-menu-submenu-title) span {
  margin-left: 20px;
  overflow: hidden;
}

.menu svg,
.stack svg {
  fill: #ffffff;
}

:global(.ant-menu-submenu-popup > .ant-menu) {
  background-color: primary-color !important;
}
:global(.ant-dropdown-menu-title-content) {
  display: flex;
  flex-direction: row;
}

.stack {
  height: 100%;
  font-size: 0.875rem !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stack > span,
.stack > div {
  line-height: 1.25rem !important;
}

:global(.ant-menu-item-selected),
:global(.ant-menu-item-active) {
  background: linear-gradient(
    90deg,
    rgba(2, 56, 255, 0.6),
    transparent
  ) !important;
}

.header {
  padding: 0 20px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  flex: 1;
  overflow-y: hidden;
}

.scroll {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem !important;
  color: text-color-2;
  text-align: center;
  border: 1px solid button-border-color;
}

.breadcrumb {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.breadcrumb::-webkit-scrollbar {
  display: none;
}

.item,
.current {
  line-height: 1.875rem;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 12px;
  margin-left: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.item {
  border: 1px solid button-border-color;
}

.item > span,
.current > span {
  margin-left: 10px;
}

.item {
  color: text-color-2;
}

.current {
  color: #ffffff;
  background-color: primary-color;
}

.user {
  color: text-color-2;
  font-size: 0.875rem;
  margin-left: 20px;
}

.user:hover {
  color: text-color-2;
}

.overlay {
  height: 3.125rem;
  color: text-color-2;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.overlay > svg {
  margin-right: 10px;
}

.overlay > svg path,
.overlay > svg rect,
.overlay > svg circle {
  color: text-color-2 !important;
  fill: text-color-2 !important;
}

.upgrade {
  composes: overlay;
  color: primary-color;
}

.upgrade > svg path,
.upgrade > svg rect,
.upgrade > svg circle {
  color: primary-color !important;
  fill: primary-color !important;
}

.version {
  color: primary-color;
}

.version > span {
  margin-left: 18px;
  font-size: 0.75rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* ::-webkit-scrollbar-track:hover {
  background: transparent;
} */

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: #bedcfd !important;
}
/* 
::-webkit-scrollbar-button {
  display: none !important;
} */

.back {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15) !important;
  position: absolute;
  top: 0;
  z-index: 1200;
}
.tip{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 20px;
}