/* .table {
  min-height: 450px;
}

.table :global(.ant-spin-container) {
  height: 450px !important;
} */

.tableBorded th {
  border: 1px solid #333 !important;
  border-right: none !important;
  padding:0!important;
}

.tableBorded th:last-child {
  border-right: 1px solid #333 !important;
}

.tableBorded td {
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
  padding:0 !important;
}

.tableBorded td:last-child {
  border-right: 1px solid #333 !important;
}
