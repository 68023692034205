.card {
  position: relative;
  width: 500px;
  height: 274px;
  line-height: 20px;
  /* background-color: rgba(255, 255, 255, 1); */
  text-align: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
}
.iconImg{
  width: 20px;
  height: 20px;
}
