.form :global(.ant-form-item) {
  padding: 0 !important;
}

.form {
  margin: 10px;
  background-color: #fff;
  height: calc(100% - 70px);
  border-radius: 10px;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tip {
  margin-top: 4px;
}

.buttonMain {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

/* .form :global(.tox) {
  height: 500px !important;
} */

/* 
.EditorContent :global(.mce-content-body) {
  overflow: auto !important;
} */

