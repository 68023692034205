.content {
  height: calc(100% - 20px);
  margin: 10px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.freePatients {
  flex: 1;
  border-radius: 10px !important;
}

.tip {
  margin-top: 6px;
  margin-left: -15px;
}

.selectDoctorListMain {
  margin: -10px 0 16px 106px;
}

.selectDoctorListMains {
}

.listColor {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #ecf1ff;
  padding: 0 10px;
  margin-right: 10px;
  color: #2d7bff;
  margin-bottom: 5px;
  border: 1px solid #2d7bff;
}

.listColors {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #f5f5f5;
  padding: 0 0 0 10px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
  border: 1px solid #d9d9d9;
}

.listColorMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.listColorMains {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
}

.listColorMainName {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closeIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2d7bff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
