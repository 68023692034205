@value colors: "../../colors.css";
@value primary-color, text-color-1, text-color-2, text-color-3, input-border-color from colors;

.viewport {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.internet{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.banner {
  width: 560px;
  height: 600px;
  background-image: url(./assets/banner.png);
}

.title {
  width: 100%;
  font-weight: bold;
  line-height: 1.875rem;
  text-align: center;
  color: primary-color;
  font-size: 1.875rem;
  margin: 100px 0 80px 0;
  white-space: nowrap;
}

.login {
  position: relative;
  width: 500px;
  height: 560px;
  min-width: 440px;
  background-color: white;
  border-radius: 0 2px 2px 0;
}

.form {
  padding: 0 60px;
}

.form :global(.ant-select-selection-placeholder),
.form :global(.ant-select-selection-item) {
  font-size: 1rem !important;
}

.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.entry label,
.password label {
  color: #89a8d4 !important;
}

.entry :global(.ant-form-item.control),
.password :global(.ant-form-item.control) {
  position: relative;
}

.entry :global(.ant-form-item-explain),
.password :global(.ant-form-item-explain) {
  position: absolute;
  top: 34px;
}

.entry :global(.ant-form-item-control),
.entry :global(.ant-input-affix-wrapper),
.password :global(.ant-form-item-control) {
  border: none;
  border-bottom: 1px solid #eeeeee;
}

.entry :global(.ant-input-affix-wrapper),
.entry :global(.ant-select-selector),
.password :global(.ant-input-affix-wrapper) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.entry input,
.password input {
  font-size: 1rem;
  border-bottom: none !important;
}

.form :global(.ant-select-arrow) {
  right: 0;
}

.entry:focus-within label,
.password:focus-within label {
  color: primary-color !important;
}

.form input,
.form input:hover {
  font-size: 1rem;
  border: 0;
  border-radius: 0;
}

.form input:focus,
.form input:active {
  border-radius: 0;
  border: 0;
}

.form :global(.ant-form-item) {
  margin-bottom: 35px;
}

.form ::placeholder {
  font-size: 1rem;
  color: text-color-3;
}

.corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0px;
  border-right: 80px solid transparent;
  border-bottom: 80px solid #fff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.password {
  margin-bottom: 20px !important;
}

.remember {
  margin-bottom: 25px !important;
}

.remember label {
  color: text-color-3;
}

.remember :global(.ant-form-item-control-input-content) {
  display: flex;
  justify-content: space-between;
}

.submit {
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
}

.tenant:global(.ant-select-disabled):global(.ant-select):not(:global(.ant-select-customize-input))
  :global(.ant-select-selector) {
  color: text-color-1 !important;
}

.qrcode {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
