.container {
  width: 100%;
  display: none;
  padding-top:20px

}

.container {
  content: counter(page);
}

@media print {
  .container {
    display: block;
  }
}
