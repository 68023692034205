.container {
   height: 98%;
  margin: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.containerMain {
  display: flex;
  flex-direction: column;
  height: 93%;
}

.oneBlock {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 17px;
  height: 156px;
}

.threeBlock{
 padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 17px;
  height: 156px;
}

.oneBlockTitle {
  margin-bottom: 13px;
  font-weight: bold;
}

.twoBlock {
  padding: 20px 20px  0 20px;
  flex: 1;
  background: #fff;
  border-radius: 10px;
}

.threeBlockContent{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
