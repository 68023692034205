.titleMain {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #999999;
  font-size: 14px;
}

.titleName {
  color: #333333;
  font-size: 15px;
}

.reasonContent {
  background-color: #f7f7f7;
  color: #999999;
  font-size: 14px;
  padding: 10px;
  margin-top: 6px;
}
